<template>
  <el-dialog
      :title="'修改'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item label="UID" prop="uid">
        <el-input v-model="dataForm.uid" placeholder="UID" disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="ID" prop="userId">
        <el-input v-model="dataForm.userId" placeholder="ID" disabled="true" ></el-input>
      </el-form-item>
      <el-form-item label="NAME" prop="nickname">
        <el-input v-model="dataForm.nickname" placeholder="NAME"></el-input>
      </el-form-item>
      <el-form-item label="AGENCY" prop="agency">
        <el-input v-model="dataForm.agency" placeholder="AGENCY"></el-input>
      </el-form-item>
      <el-form-item label="PASSWD" prop="passwd">
        <el-input v-model="dataForm.passwd" placeholder="PASSWD"></el-input>
      </el-form-item>
      <el-form-item label="OnBoardTime" prop="onBoardTime">
        <el-input v-model="dataForm.onBoardTime" placeholder="OnBoardTime"></el-input>
      </el-form-item>
      <el-form-item label="头像">
        <el-upload class="avatar-uploader" :action="ossUploadAvatarUrl" :show-file-list="false"
                   :on-success="handleArAvatarSuccess" :before-upload="beforeAvatarUpload">
          <img v-if="dataForm.avatar" :src="dataForm.avatar" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="职业" prop="occupation">
        <el-input v-model="dataForm.occupation" placeholder="职业"></el-input>
      </el-form-item>
      <el-form-item label="首句" prop="introduce">
        <el-input type="textarea" rows="3" v-model="dataForm.introduce" style="width: 30vw;"></el-input>
      </el-form-item>
      <el-form-item label="个性签名" prop="bio">
        <el-input type="textarea" rows="3" v-model="dataForm.bio" style="width: 30vw;"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="dataForm.status">
          <el-radio :label="0">正常</el-radio>
          <el-radio :label="1">删除(软删除)</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
const sourceDataForm = {
      id: '',
      uid: '',
      userId: '',
      nickname: '',
      agency:'',
      atime: '',
      utime: '',
      email: '',
      passwd: '',
      onBoardTime: '',
      status:'',
      createTimeArr: [],
      avatar:'',
      occupation:'',
      introduce:'',
      bio:''

}
export default {

  async created() {

    this.ossUploadFileUrl = this.$http.adornUrl(
        `/sys/oss/upload/avatarCompress?token=${this.$cookie.get("token")}`
    );
  },
  data () {
    return {
      visible: false,
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      ossUploadAvatarUrl:this.$http.adornUrl(
          `/sys/oss/upload/avatarCompress?token=${this.$cookie.get("token")}`
      ),
      dataRule: {
      }
    }
  },
  methods: {
    init (row) {
      this.dataForm.id = row.id
      this.dataForm.uid = row.uid
      this.dataForm.userId = row.userId
      this.dataForm.nickname = row.nickname
      this.dataForm.agency = row.agency
      this.dataForm.atime = row.atime
      this.dataForm.onBoardTime = row.onBoardTime
      this.dataForm.status = row.status
      this.dataForm.passwd = row.passwd
      this.dataForm.onBoardTime = row.onBoardTime
      this.dataForm.avatar = row.avatar
      this.dataForm.occupation = row.occupation
      this.dataForm.bio = row.bio
      this.dataForm.introduce = row.introduce
      this.visible = true
    },
    handleAvatarRemove(res, file) {

    },
    handlePreview(res,file){

    },
    handleFileRemove(res,file){

    },
    handleArAvatarSuccess(res, file) {
      if (res && res.code === 0) {
        this.dataForm.avatar = res.url
        this.dataForm.avatarExpand = res.fixedUrl
      } else {
        this.$message.error('上传头像图片失败:' + res.msg)
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或者png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/admin/anchor/update`),
            method: 'post',
            data: this.$http.adornData({
              'id': this.dataForm.id,
              'uid': this.dataForm.uid,
              'userId': this.dataForm.userId,
              'agency': this.dataForm.agency,
              'nickname':this.dataForm.nickname,
              'passwd':this.dataForm.passwd,
              'status':this.dataForm.status,
              'onBoardTime':this.dataForm.onBoardTime,
              'avatar':this.dataForm.avatar,
              'occupation':this.dataForm.occupation,
              'bio':this.dataForm.bio,
              'introduce':this.dataForm.introduce
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    }
  }
}
</script>
