<template>
  <div>
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item label="NAME">
        <el-input v-model="dataForm.nickname" placeholder="NAME" clearable></el-input>
      </el-form-item>
      <el-form-item label="Agency">
        <el-input v-model="dataForm.agency" placeholder="Agency" clearable></el-input>
      </el-form-item>
      <el-form-item label="ID">
        <el-input v-model="dataForm.userId" placeholder="ID" clearable></el-input>
      </el-form-item>
      <el-form-item label="UID">
        <el-input v-model="dataForm.uid" placeholder="UID" clearable></el-input>
      </el-form-item>
      <el-form-item label="创建日期">
        <el-date-picker v-model="dataForm.createTimeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        align="right">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()" type="primary">搜索</el-button>
        <el-button @click="refresh()">重置</el-button>
        <el-button @click="uploadAnchor()" type="danger">批量导入</el-button>
        <el-button @click="exportList()" type="primary" >导出</el-button>
        <a href="https://storage.elwaha.ai/app/%E4%B8%BB%E6%92%AD%E5%AF%BC%E5%85%A5%E6%96%B0.xls" style="margin-left: 20px;color: red; text-decoration:underline; ">导入格式</a>
        <!-- <el-button @click="exportList()"  style="background-color: #00BFBF; color: #fff;">导出</el-button> -->
      </el-form-item>
    </el-form>

    <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%; ">

      <el-table-column prop="nickname" header-align="center" align="center" label="NAME">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.nickname ? '/' : scope.row.nickname }}
                    </span>
        </template>
      </el-table-column>
      <el-table-column prop="agency"  header-align="center" align="center" label="AGENCY">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.agency ? '/' : scope.row.agency }}
                    </span>
        </template>
      </el-table-column>

      <el-table-column prop="userId"  header-align="center" align="center" label="ID">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.userId ? '/' : scope.row.userId }}
                    </span>
        </template>
      </el-table-column>

      <el-table-column prop="onBoardTime"  header-align="center" align="center" label="Onboard Time">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.onBoardTime ? '/' : scope.row.onBoardTime }}
                    </span>
        </template>
      </el-table-column>

      <el-table-column prop="uid"  header-align="center" align="center" label="UID">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.uid ? '/' : scope.row.uid }}
                    </span>
        </template>
      </el-table-column>
      <el-table-column prop="avatar" header-align="center" align="center" label="头像" width="100">
        <template slot-scope="scope">
          <img style="width: 40px; height: 40px" :src="scope.row.avatar" />
        </template>
      </el-table-column>
      <el-table-column prop="occupation"  header-align="center" align="center" label="职业">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.occupation ? '/' : scope.row.occupation }}
                    </span>
        </template>
      </el-table-column>

      <el-table-column prop="introduce"  header-align="center" align="center" label="首句">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.introduce ? '/' : scope.row.introduce }}
                    </span>
        </template>
      </el-table-column>

      <el-table-column prop="bio"  header-align="center" align="center" label="签名">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.bio ? '/' : scope.row.bio }}
                    </span>
        </template>
      </el-table-column>

      <el-table-column prop="opName" header-align="center" align="center" label="操作人">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.opName ? '/' : scope.row.opName }}
                    </span>
        </template>
      </el-table-column>

      <el-table-column prop="status"  sortable header-align="center" align="center" label="发布状态">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.status === 0" type="primary">正常</el-tag>
            <el-tag v-if="scope.row.status === 1" type="info">失效</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="utime" header-align="center" align="center" label="创建时间">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center"  width="200"  label="操作">
        <template slot-scope="scope">
          <el-button type="text"   size="small" style="margin-right:10px" @click="addOrUpdateHandle(scope.row)" >编辑
          </el-button>
<!--          <el-button type="text" size="small"  @click="imManage(scope.row.uid)">IM管理
          </el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
                   :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
                   layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <upload-anchor v-if="uploadAnchorVisible" ref="uploadAnchor" @refreshDataList="getDataList"></upload-anchor>
    <el-dialog title="IM对话管理" :visible.sync="imManageDialogVisible" width="94%" top="1vh">
      <ImManage :uid=currentLoginVirtualUid :ipList="allVirtualIpList">
      </ImManage>
    </el-dialog>
  </div>
</template>
<script>
import AddOrUpdate from "./operate-add-or-update";
import { langOpt, pickerOptions } from "@/views/common/commonOpt";
import uploadAnchor from "./upload-anchor.vue";
import ImManage from "./ImManage.vue"

const sourceDataForm = {
  id: '',
  uid: '',
  userId: '',
  nickname: '',
  agency:'',
  atime: '',
  utime: '',
  email: '',
  passwd: '',
  onBoardTime: '',
  opName:'',
  createTimeArr: [],
  avatar:'',
  occupation:'',
  introduce:'',
  bio:''
}
export default {
  data() {
    return {
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      langOpt: langOpt,
      pickerOptions: pickerOptions,
      addOrUpdateVisible: false,
      uploadAnchorVisible:true,
      imManageDialogVisible: false,
      allVirtualIpList: [],
      currentLoginVirtualUid: -1
    }
  },
  components: {
    AddOrUpdate,
    ImManage,
    uploadAnchor
  },
  activated() {
    this.getDataList()
  },
  methods: {
    refresh() {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.pageIndex = 1
      this.pageSize = 10
      this.getDataList()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/anchor/list'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          startTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
          endTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
          uid: this.dataForm.uid,
          page: this.pageIndex,
          limit: this.pageSize,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.result.data
          this.totalPage = data.result.last_page
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    uploadAnchor(){
      this.uploadAnchorVisible = true
      this.$nextTick(() => {
        this.$refs.uploadAnchor.visible = true
        this.$refs.uploadAnchor.fileList = []
      })
    },
    // 导出
    exportList() {

      // window.open(
      //     this.$http.adornUrl('/cs/table/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`,
      //     "_blank"
      // );
      const exportData = {
        ...this.dataForm,
        startTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
        endTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
        uid: this.dataForm.uid,
        page: this.pageIndex,
        limit: this.pageSize,
      }
      const paramJson = encodeURI(JSON.stringify(exportData).replace(/\+/g, "%2b"))
      window.location.href = this.$http.adornUrl('/admin/anchor/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`
    },
    imManage(uid) {
      this.currentLoginVirtualUid = uid
      this.imManageDialogVisible = true;
      // this.$router.push({ name: "virtual-imManage", query: {uid: uid} })
    },
  },
}
</script>

<style scoped lang="scss">
.el-table {
  .el-button+.el-button {
    margin-left: 0;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .el-tag--small {
  margin-right: 2px;
}
</style>
